import dynamic from 'next/dynamic';
import type { ReactElement } from 'react';
import React from 'react';

import CustomMetatag from '@/components/layouts/custom-metatag';
import Error, { ErrorProps } from '@/components/sections/error';

const Layout =
  process.env.NEXT_PUBLIC_WEB === 'cv'
    ? dynamic(() => import('@/components/layouts'))
    : dynamic(() => import('@/components/layouts/lcv'));

const Footer =
  process.env.NEXT_PUBLIC_WEB === 'cv'
    ? dynamic(() => import('@/components/layouts/cv/footer-group'))
    : dynamic(() => import('@/components/layouts/lcv/footer-group'));

export async function getStaticProps() {
  return {
    props: {},
  };
}

const Page = () => {
  let title;
  let description;

  switch (process.env.NEXT_PUBLIC_WEB) {
    case 'cv':
      title = '404 - ISUZU TRUCKS Thailand Official';
      description = 'ขออภัย ไม่พบหน้าที่คุณต้องการ';
      break;
    case 'lcv':
      title = '404 - อีซูซุประเทศไทย Isuzu Thailand Official';
      description = 'ขออภัย ไม่พบหน้าที่คุณต้องการ';
      break;
  }

  const errorData: ErrorProps = {
    title: '404',
    description: 'ขออภัย ไม่พบหน้าที่คุณต้องการ',
    buttons: [
      {
        label: 'กลับไปยังหน้าหลัก',
        url: '/',
        icon: 'home',
      },
    ],
    imageMobile: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltfa0d8224aa47190c/668514f9c98488830c5e28c5/bg-error-mobile.jpg',
      alt: '404',
      width: 1600,
      height: 1600,
    },
    imageDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltf18074a76a93797f/668514f9c98488ec815e28c9/bg-error-desktop.jpg',
      alt: '404',
      width: 1600,
      height: 600,
    },
  };

  const errorLCVData: ErrorProps = {
    title: '404',
    description: 'ขออภัย ไม่พบหน้าที่คุณต้องการ',
    buttons: [
      {
        label: 'กลับไปยังหน้าหลัก',
        url: '/',
        icon: 'home',
      },
    ],
    imageMobile: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt29c2126d778b9fa1/67441f4c4d7c439673f6179c/404-mobile.jpg',
      alt: '404',
      width: 1600,
      height: 1600,
    },
    imageDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blta6a89ba14eeba043/67441f4c0da03c7a7eb52c23/404-desktop.jpg',
      alt: '404',
      width: 1600,
      height: 600,
    },
  };

  return (
    <>
      <CustomMetatag title={title} description={description} />
      <main>
        {process.env.NEXT_PUBLIC_WEB === 'cv' ? (
          <Error {...errorData} />
        ) : (
          <Error {...errorLCVData} />
        )}
      </main>
      <Footer />
    </>
  );
};

export default Page;

Page.getLayout = function getLayout(page: ReactElement) {
  return <Layout>{page}</Layout>;
};
