import React from 'react';

import { ButtonGroup } from '@/components/shared/buttons/button-group';
import { Text } from '@/components/shared/element/text';
import { Box } from '@/components/shared/layout/box';
import { Container } from '@/components/shared/layout/container';
import { Grid } from '@/components/shared/layout/grid';
import { Section } from '@/components/shared/layout/section';
import { Image } from '@/components/shared/media/image';
import { generateCommonImageSources } from '@/components/shared/media/image/generate-sources';
import { ImageProps } from '@/components/shared/media/image/Image.props';
import { IButton } from '@/types/shared';

export interface ErrorProps {
  title?: string;
  description?: string;
  buttons?: IButton[];
  imageMobile?: ImageProps;
  imageDesktop?: ImageProps;
}

const ErrorSection: React.FC<ErrorProps> = ({
  title,
  description,
  buttons,
  imageMobile,
  imageDesktop,
}) => {
  return (
    <Section test="section_error">
      {!!imageMobile && !!imageDesktop && (
        <Image
          priority
          src={imageMobile.src}
          alt={imageMobile.alt}
          width={imageMobile.width}
          height={imageMobile.height}
          layout="responsive"
          aspectRatio={{ '@initial': '1by1', '@lg': '8by3' }}
          sources={generateCommonImageSources({
            mobileSrc: imageMobile.src,
            mobileOptions: {
              crop: '1:1',
            },
            desktopSrc: imageDesktop.src,
            desktopOptions: {
              crop: '8:3',
            },
          })}
        />
      )}
      <Box
        position="absolute"
        inset="0"
        display="flex"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
      >
        <Container>
          <Grid justifyContent="center" rowGap="8">
            <Box>
              {!title && (
                <Text as="h1" font="bold" variant="text-white" sr-only>
                  404
                </Text>
              )}
              {!!title && (
                <Text as="h1" size="text-display" variant="text-white">
                  {title}
                </Text>
              )}
              {!!description && (
                <Text
                  as="p"
                  size="h5"
                  font="bold"
                  variant="text-white"
                  wrap
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              )}
            </Box>
            {!!buttons && buttons.length > 0 && (
              <ButtonGroup buttons={buttons} justify="center" />
            )}
          </Grid>
        </Container>
      </Box>
    </Section>
  );
};
ErrorSection.displayName = 'ErrorSection';
export default ErrorSection;
